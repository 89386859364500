html {
  height: 100%;
  width: 100vw;
}

body {
  padding-right: 0 !important;
  overflow-x: hidden;
  margin: 0;
}

header {
  padding-right: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

* {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

